<template>
  <div class="order-box">
    <div class="no-order" v-if="!orders.length"><img class="no-order-img" src="../../assets/product/order.png" alt="">
    </div>
    <div class="order-item" v-for="(item, index) in orders" :key="index">
      <div class="order-header">
        <div>
          <div>{{ item.order_status }}</div>
          <div><span>{{ $t('orderBox.ddh') }}：{{ item.order_no }}</span></div>
          <div><span>{{ $t('orderBox.cjsj') }}：{{ item.create_time }}</span> </div>
        </div>
        <div class="price">{{ moneyfilter(item.total_price) }}</div>
      </div>
      <div class="order-body">
        <div class="goods-list">
          <div class="commodity-item" v-for="(i, j) in item.order_item_dolist" :key="j">
            <img :src="i.product_icon" alt="">
            <div class="info">
              <div class="name">{{ i.product_name }}</div>
              <div class="price">{{ moneyfilter(i.product_price) }} <span>x{{ i.product_num }}</span></div>
            </div>
          </div>
        </div>
        <div class="but">
          <el-button class="item fla" size="mini" type="warning" @click="detail(item)">{{ $t('orderBox.ddxq')
          }}</el-button>
          <el-button v-if="item.order_status == $t('orderBox.dzf') && item.order_status !== $t('orderBox.sdgb')"
            class="item" size="mini" type="danger" @click="offOrder(item)">{{ $t('orderBox.qxdd') }}
          </el-button>
          <el-button
            v-if="(item.order_status == $t('orderBox.wzf') && item.order_status !== $t('orderBox.sdgb') && item.order_status !== $t('orderBox.csgb')) || (item.order_status == $t('orderBox.zfsb') || item.order_status == $t('orderBox.dzf'))"
            class="item" size="mini" type="primary" @click="pay(item)">{{ $t('orderBox.qzf') }}
          </el-button>
          <el-button v-if="item.order_status == $t('orderBox.ckcg') || item.order_status == $t('orderBox.ywc')"
            class="item" size="mini" type="danger" @click="logistics(item)">{{ $t('orderBox.kcwl') }}
          </el-button>
          <el-button v-if="item.order_status == $t('orderBox.ckcg') || item.order_status == $t('orderBox.ywc')"
            class="item" size="mini" type="primary" @click="goods(item)">{{ $t('orderBox.qrsh') }}
          </el-button>
          <el-button v-if="item.order_status == $t('orderBox.ckcg') || item.order_status == $t('orderBox.ywc') || item.order_status != $t('orderBox.dzf')"
            class="item" size="mini" type="success" @click="give(item)">{{ $t('orderBox.sqsh') }}
          </el-button>
          <!-- 支付方式 -->
          <el-dialog :title="$t('pay.zffs')" :visible.sync="open" width="30%">
            <el-form ref="form" :model="form" label-width="180px">
              <el-form-item :label="$t('pay.qxzndzffs')">
                <el-select v-model="form.payType" :placeholder="$t('pay.qxzndzffs')" style="width:360px;">
                  <el-option :label="$t('pay.gnzf')" value="DomesticPayment"></el-option>
                  <el-option :label="$t('pay.gjzf')" value="InternationalPayments"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item style="margin-left:200px">
                <el-button @click="open = false">{{ $t('pay.qx') }}</el-button>
                <el-button type="primary" @click="onSubmit">{{ $t('pay.qr') }}</el-button>
              </el-form-item>
            </el-form>
          </el-dialog>
        </div>
      </div>

    </div>
    <el-dialog :title="$t('orderBox.qxzqxddyy')" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div class="cause">
        <el-radio-group v-model="causes">
          <el-radio-button v-for="item in reason" :key="item.id" :label="item.id">{{ item.reasons }}</el-radio-button>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('orderBox.qx') }}</el-button>
        <el-button type="primary" @click="ensure">{{ $t('orderBox.qr') }}</el-button>
      </span>
    </el-dialog>
    <!-- 售后服务 -->
    <el-dialog :title="$t('orderBox.shfw')" :visible.sync="giveOpen" width="600px" v-for="i in orders" :key="i.order_no">
      <el-form ref="form" :model="form" label-width="120px" >
        <el-form-item :label="$t('orderBox.qxzshfw')">
          <el-select v-model="form.giveServe" :placeholder="$t('orderBox.qxzshfw')" style="width:440px;"
            @change="serveOption">
            <el-option :label="$t('orderBox.gzjj')" value="1"></el-option>
            <el-option :label="$t('orderBox.hh')" value="2"></el-option>
            <el-option :label="$t('orderBox.thtk')" value="3"></el-option>
            <el-option :label="$t('orderBox.tkbth')" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号" v-show="gzjjShow">
          <el-input v-model="form.order_no" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系电话" v-show="gzjjShow">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="问题描述" v-show="wtShow">
          <el-input type="textarea" v-model="form.miaoshu" :rows="3"></el-input>
        </el-form-item>
        <el-form-item label="备注" v-show="hhShow">
          <el-input type="textarea" v-model="form.beizhu" :rows="3"></el-input>
        </el-form-item>
        <el-form-item style="margin-left:260px">
          <el-button @click="qx">{{ $t('pay.qx') }}</el-button>
          <el-button type="primary" @click="giveSubmit">{{ $t('pay.qr') }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'BbjtwebsiteOrderBox',
  props: {
    ordersList: {
      type: Array,
      default() {
        return []
      }
    },
  },
  watch: {
    ordersList: {
      handler(message) {
        this.orders = message
        console.log(message)
      },
      deep: true,
    }

  },

  data() {
    return {
      orders: [],
      // orders: this.ordersList,
      dialogVisible: false,
      causes: 1,
      order_no: null,
      reason: [],
      open: false,
      form: {
        payType: "",
        giveServe: "",
        order_no: "",
        phone: "",
        miaoshu: "",
        beizhu: "",
        type: ''
      },
      moneyId: '',
      url: "",
      giveOpen: false,
      gzjjShow: false,
      hhShow: false,
      wtShow: false,
    };
  },

  mounted() {
    this.orderreason()
    console.log(this.orders)
  },

  methods: {


    // 下拉选择
    serveOption(e) {
      this.wtShow = false
        this.gzjjShow = false
        this.hhShow = false
      if (this.form.giveServe == 1) {
        console.log('故障解决')
        console.log(e)
        this.wtShow = true
        this.gzjjShow = true
        this.hhShow = false
        this.type = e
      } else if (this.form.giveServe == 2 || this.form.giveServe == 3 || this.form.giveServe == 4) {
        this.hhShow = true
        this.wtShow = false
        this.gzjjShow = true
        this.type = e
        console.log('换货')
      }
    },
    // 提交
    async giveSubmit() {
      if (this.form.giveServe == 1) {
        var data = {
          type: this.type,
          order_no: this.form.order_no,
          phone: this.form.phone,
          descs: this.form.miaoshu
        }
        var result = await this.api.post(this.apiPath.aftersaleService, data)
        console.log(result)
      } else if (this.form.giveServe == 2 || this.form.giveServe == 3 || this.form.giveServe == 4) {
        var data = {
          type: this.type,
          order_no: this.form.order_no,
          phone: this.form.phone,
          descs: this.form.beizhu
        }
        var res = await this.api.post(this.apiPath.aftersaleService, data)
        console.log(result)
        console.log('换货')
      }

      this.form.giveServe = ''
      this.gzjjShow = false
      this.giveOpen = false
    },
    // 取消
    qx() {
      this.giveOpen = false
      this.form.giveServe = '',
        this.form.phone = '',
        this.form.miaoshu = '',
        this.form.beizhu = ''
    },
    // 售后服务
    give(item) {
      this.giveOpen = true
      console.log(item)
      this.form.order_no = item.order_no
          this.form.phone = item.user_phone

    },
    // 提交
    async onSubmit() {
      if (this.form.payType == "DomesticPayment") {
        console.log('ok')
        localStorage.setItem("order_no", JSON.stringify(this.moneyId));
        this.$router.push({
          name: 'payment',
        });
      }
      if (this.form.payType == "InternationalPayments") {
        var href = location.href
        console.log(href)
        var order_no = localStorage.getItem('order_no')
        // 156067938548318208
        let data = {
          orderNo: this.moneyId,
          frontURL: href
        }

        console.log('no')
        let InternationalPayments = await this.api.post(this.apiPath.InternationalPayments, data);
        console.log(InternationalPayments)
        this.url = InternationalPayments
        console.log(this.url)
        window.location.href = this.url
      }

    },
    offOrder(item) {
      this.order_no = item.order_no
      this.dialogVisible = true
    },
    handleClose(done) {
      this.$confirm(this.$t('orderBox.qrgb'))
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    async orderreason() {
      let cc = {
        type: 0
      }
      let res = await this.api.get(this.apiPath.orderreason, cc);
      this.reason = res
    },
    async ensure() {
      // 取消订单
      let data = {
        order_no: this.order_no,
        reasons_id: this.causes
      }
      let offOrder = await this.api.post(this.apiPath.offOrder, data);
      if (offOrder.code == 2) {
        this.$emit('refresh', true)
        this.$message({
          message: this.$t('orderBox.qxddcg'),
          type: 'success'
        });
      } else {
        this.$message({
          message: offOrder.message,
          type: 'warning'
        });
      }
      // this.offOrder = offOrder
      this.dialogVisible = false
    },
    detail(item) {
      // 订单详情
      this.$router.push({
        path: "/orderDetail?no=" + item.order_no,
      });
    },
    pay(item) {
      // localStorage.setItem("order_no", JSON.stringify(item.order_no));
      // this.$router.push({
      //   name: 'payment',
      // });
      console.log(item)
      this.moneyId = item.order_no
      console.log(this.moneyId)
      this.open = true
    },
    logistics(item) {
      console.log(item);
      this.$router.push({
        path: `/logisticsInfo`,
        query: {
          order_no: item.order_no,
          phone: item.user_phone,
          logistics_no: item.logistics_no
        }
      });
    },
    goods(item) {
      this.$confirm(this.$t('orderBox.nqryshm'), this.$t('orderBox.ts'), {
        confirmButtonText: this.$t('orderBox.qr'),
        cancelButtonText: this.$t('orderBox.qx'),
        type: 'warning'
      }).then(() => {
        this.confirmGoods(item)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('orderBox.yqxsh')
        });
      });
    },
    async confirmGoods(item) {
      let data = {
        order_no: item.order_no,
        order_status: 5
      }
      let res = await this.api.post(this.apiPath.confirmGoods, data);
      console.log(res);
      if (res.code == 2) {
        this.$message({
          type: 'success',
          message: res.message
        });
      } else {
        this.$message.error(res.message);
      }
    }
  },
};
</script>

<style lang="less" scoped>
.no-order {
  text-align: center;

  .no-order-img {
    width: 400px;
    height: 400px;
  }
}

.order-box {
  width: 100%;

  .order-item {
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid #eee;

    .order-header {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      color: #999;

      .data {
        margin-left: 20px;
      }

      .price {
        line-height: 40px;
        color: red;
      }
    }

    .order-body {
      display: flex;
      border-top: 1px solid #eee;
      justify-content: space-between;

      .goods-list {
        .commodity-item {
          padding: 20px;
          color: #999;
          display: flex;

          .info {
            padding: 0 10px;
          }

          img {
            width: 40px;
            height: 50px;
          }
        }
      }

      .but {
        padding: 10px 15px;
        display: grid;

        .item {
          height: 30px;
          width: 80px;
          margin-bottom: 10px;
        }

        .fla {
          margin-left: 10px;
        }
      }
    }
  }
}

.ivu-btn {
  height: 30px;
}

/deep/ .el-input__inner {
  padding: 0 10px;
}
</style>