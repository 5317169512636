<template>
  <div class="my-order">
    <div class="order">
      <div class="ivu-card">
        <div class="card-laft">{{$t('MyOrder.dd')}}</div>
      </div>
      <div class="card-cn">
        <el-tabs v-model="activeName"
                 @tab-click="handleClick">
          <el-tab-pane :label="$t('MyOrder.qbdd')"
                       name="first"
                       class="sdfasdg">
            <order-box v-if="status == 0"
                       :ordersList="ordersList"
                       @refresh="refresh"></order-box>
          </el-tab-pane>
          <el-tab-pane :label="$t('MyOrder.dfk')"
                       name="second">
            <order-box v-if="status == 1"
                       :ordersList="ordersList"></order-box>
          </el-tab-pane>
          <el-tab-pane :label="$t('MyOrder.dfh')"
                       name="goods">
            <order-box v-if="status == 2"
                       :ordersList="ordersList"></order-box>
          </el-tab-pane>
          <el-tab-pane :label="$t('MyOrder.dsh')"
                       name="third">
            <order-box v-if="status == 4"
                       :ordersList="ordersList"></order-box>
          </el-tab-pane>
          <el-tab-pane :label="$t('MyOrder.ywc')"
                       name="fourth">
            <order-box v-if="status == 5"
                       :ordersList="ordersList"></order-box>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="page">
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     layout="prev, pager, next"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import orderBox from '../../components/common/orderBox.vue';
export default {
  components: { orderBox },
  name: 'MyOrder',

  data () {
    return {
      activeName: 'first',
      ordersList: [],
      total: null,
      status: 0,
    };
  },

  mounted () {
    this.getOrdersList()
  },

  methods: {
    async handleClick (tab, event) {
      if (tab.label == '待付款') {
        this.status = 1
      } else if (tab.label == '待收货') {
        this.status = 4
      } else if (tab.label == '待发货') {
        this.status = 2

      } else if (tab.label == '已完成') {
        this.status = 5
      } else {
        this.status = 0
        this.getOrdersList()
        return
      }
      if (this.status) {
        let data = {
          page_size: 5,
          page_num: 1,
          order_status: this.status,
          is_root: 0
        }
        let res = await this.api.post(this.apiPath.orderList, data);
        console.log(res)
        this.ordersList = res.result_list
        // console.log(this.ordersList[0].user_phone);
        this.total = res.total_page
      }
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    refresh (e) {
      if (e) {
        this.getOrdersList()
      }
    },
    async handleCurrentChange (val) {
      let data = {
        page_size: 5,
        page_num: val,
        is_root: 0
      }
      let res = await this.api.post(this.apiPath.orderList, data);
      this.ordersList = res.result_list
      this.total = res.total_record
    },
    async getOrdersList () {
      let data = {
        page_size: 5,
        is_root: 0
      }
      let res = await this.api.post(this.apiPath.orderList, data);
      this.ordersList = res.result_list
      this.total = res.total_record
    }
  },
};
</script>

<style lang="less" scoped>
.my-order {
  padding: 40px;
  .order {
    width: 100%;
    display: flex;
    .card-cn {
      width: 100%;
    }
  }
  .page {
    width: 85%;
    float: right;
  }
  .ivu-card {
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .card-laft {
      width: 90px;
      height: 25px;
      line-height: 25px;
      border-left: 3px solid #7f0114;
      padding-left: 10px;
      font-size: 15px;
    }
  }
}
.sdfasdg {
  width: 100%;
}
@media screen and (max-width: 750px) {
  .el-tabs {
    width: 100%;
  }
  .my-order {
    padding: 0 15px;
    .page {
      width: 100%;
    }
  }
  .ivu-card {
    display: none !important;
  }
}
</style>
<style>
.el-tabs {
  width: 60%;
}
.el-tabs__item:hover {
  color: #7f0114;
}
.el-tabs__item.is-active {
  color: #7f0114;
}
.el-tabs__active-bar {
  background: #7f0114;
}
</style>